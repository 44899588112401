<template>
  <div class="row contenedor" id="div-categories">
    <div class="col s12" id="div-categories-carousel" ref="categories-carousel">
      <VueSlickCarousel v-bind="settings" ref="sections" v-if="parent_categories.length > 0">
        <div v-for="(c, index) in parent_categories" :key="index">
          <div class="icon-category" @click="apply(c.id)">
            <div class="div-img">
              <img class="img-section"
              :src="c.img" :alt="'category-'+index">
            </div>
            <button class="btn-category">
              <h5>{{c.name}}</h5>
            </button>
           </div>
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import _ from 'lodash';
import axios from 'axios';

export default {
  name: 'Sections',
  data(){
    return{
      settings:{},
      parent_categories:[],
      render: false,
      slidesToShow:1,
      slidesToScroll: 1,
      slideInfinite: false,
      message: '',
    }
  },
  props: {
    categories: Array,
  },
  components: { VueSlickCarousel },
  mounted() {
    this.get_categories();
    this.ro = new ResizeObserver(this.onResize)
    .observe(this.$refs['categories-carousel'])

    axios.get('https://0.0.0.0:8083')
      .then(response => console.log(response.data))
      .catch(error => console.error(error));
      },
  methods:{
    init_carousel(){
      this.settings = {
        "dots": false,
        "infinite": this.slideInfinite,
        "arrows":true,
        "speed": 500,
        "slidesToShow": this.slidesToShow,
        "slidesToScroll": this.slidesToScroll,
        "initialSlide": 0,
        "touchMove": false,
        "responsive": [
          {
            "breakpoint": 993,
            "settings": {
              "slidesToShow": this.slidesToShow,
              "slidesToScroll": this.slidesToShow,
              "infinite": true,
              "arrows":true,
              "dots": false,
              "touchMove": true,
            }
          }
        ]
      };
    },
    apply(id){
      let name = 'products';
      let query = _.assign(
        {},
        this.$route.name === name ? _.omit(this.$route.query, 'page') : {},
        {categoryId: id},
      );
      try {
        this.$router.push({ name, query });
        // this.hide();
      } catch (error) {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      }
    },
    get_categories(){
      this.render = false;
      if(this.categories != null){
        if(this.categories.length > 0){
          this.categories.map(c => {
            let nameNormalize = c.name.normalize("NFD")
              .replace(/\p{Diacritic}/gu, "").toLowerCase();
            try{
              let img = require('../../assets/categories/'+nameNormalize+'.png');
              switch(nameNormalize) {
                case 'cable':
                  this.parent_categories.push({
                    id: c.id,
                    name: c.name,
                    img: img,
                    position: 1
                  });
                  break;
                case 'cables':
                  this.parent_categories.push({
                    id: c.id,
                    name: c.name,
                    img: img,
                    position: 2
                  });
                  break;
                case 'construccion':
                  this.parent_categories.push({
                    id: c.id,
                    name: c.name,
                    img: img,
                    position: 3
                  });
                  break;
                case 'electricidad':
                  this.parent_categories.push({
                    id: c.id,
                    name: c.name,
                    img: img,
                    position: 4
                  });
                  break;
                case 'iluminacion':
                  this.parent_categories.push({
                    id: c.id,
                    name: c.name,
                    img: img,
                    position: 5
                  });
                  break;
                case 'plomeria':
                  this.parent_categories.push({
                    id: c.id,
                    name: c.name,
                    img: img,
                    position: 6
                  });
                  break;
                case 'ferreteria':
                  this.parent_categories.push({
                    id: c.id,
                    name: c.name,
                    img: img,
                    position: 7
                  });
                  break;
                case 'herramienta':
                  this.parent_categories.push({
                    id: c.id,
                    name: c.name,
                    img: img,
                    position: 8
                  });
                  break;
                case 'hogar':
                  this.parent_categories.push({
                    id: c.id,
                    name: c.name,
                    img: img,
                    position: 9
                  });
                  break;
                case 'jardineria':
                  this.parent_categories.push({
                    id: c.id,
                    name: c.name,
                    img: img,
                    position: 10
                  });
                  break;
                case 'pintura':
                  this.parent_categories.push({
                    id: c.id,
                    name: c.name,
                    img: img,
                    position: 11
                  });
                  break;
                case 'electronica':
                  this.parent_categories.push({
                    id: c.id,
                    name: c.name,
                    img: img,
                    position: 12
                  });
                  break;
                case 'seguridad':
                  this.parent_categories.push({
                    id: c.id,
                    name: c.name,
                    img: img,
                    position: 13
                  });
                  break;
                case 'ventilacion':
                  this.parent_categories.push({
                    id: c.id,
                    name: c.name,
                    img: img,
                    position: 14
                  });
                  break;
              }
            }
            catch(e){
              //the image of the category does not exist
            }
          });
          this.parent_categories.sort((a,b) => (a.position > b.position) ? 1 :
            ((b.position > a.position) ? -1 : 0));
          this.init_carousel();
          this.render = true;
        }else{
          this.render = false;
        }
      }else{
        this.render = false;
      }
    },
    onResize () {
      let divCarousel = document.getElementById("div-categories-carousel");
      if(divCarousel != null){
        let width = divCarousel.offsetWidth;
        if(width <= 600){
          this.slidesToShow = parseInt(width/140);
        }else{
          this.slidesToShow = parseInt(width/180);
        }
        if(this.parent_categories.length % 2 === 0){
          this.slidesToScroll = 2;
          this.slideInfinite = true;
        }else{
          this.slidesToScroll = 1;
          this.slideInfinite = true;
        }
      }
      this.init_carousel();
    },

  },
  watch: {
    categories:{
      handler() {
        this.get_categories();
      },
      deep: true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.icon-category{
  padding: 0 !important;
  cursor: pointer;
  height: 125px;
}
.icon-category .div-img{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
.icon-category img {
  width: 100px !important;
  height: 100px !important;
  object-fit: cover;
  margin: 2px;
}

.icon-category .btn-category {
  position: absolute;
  transform: translate(-50%, 5%);
  -ms-transform: translate(-50%, 5%);
  font-family: "Avenir-Bold";
  border: none;
  background: none;
  border-radius: 8px;
  color: #DB272F !important;
  text-align: center !important;
  cursor: pointer;
}
.icon-category .btn-category:hover,
.div-img:hover + .btn-category{
  color: black !important;
}

.icon-category .btn-category > h5 {
  font-size: 100% !important;
  line-height: 100% !important;
  margin: 0 !important;
}
@media screen and (max-width: 993px) {
 
  .icon-category{
    height: 112px;
  }
  .icon-category img {
    width: 90px !important;
    height: 90px !important;
    margin: 1px;
  }
}
</style>